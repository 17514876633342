@import '../theme.scss';

#uspCarousel {
  padding: 80px 0;
  width: 100%;

  > .outer {
    > .inner {
      grid-template-columns: repeat(3, 1fr);
      max-width: 1350px;
      margin: 0 auto;
      text-align: center;
      align-items: start;
      transition: all 1s ease-in-out;

      .usp {
        height: 100%;
        padding: 0 10px;

        .icon {
          position: relative;
          width: auto;
          display: inline-block;

          &:after {
            content: '';
            display: block;
            width: 100%;
            margin: 0 auto;
            margin-top: 10px;
            background-color: $color-red-light;
            height: 2px;
          }
        }

        .content {
          padding-top: 10px;

          p {
            font-size: 14px;
            line-height: 1.25;
            letter-spacing: 0.01em;
            max-width: 270px;
            margin: 0 auto;

            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .navigation {
    display: none;
    padding: 20px 50px 0 50px;

    > .inner {
      grid-template-columns: repeat(3, auto);
      grid-gap: clamp(10px, 3vw, 20px);

      > .item {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-medium-grey;
        transition: all 1s ease-in-out;
        cursor: pointer;

        &.active {
          background-color: $color-blue;
        }
      }
    }
  }
}

@media (max-width: $screenBreak-mobile) {
  #uspCarousel {
    > .outer {
      padding: 0 calc((20vw / 2) - $standard-padding-x);
      overflow: hidden;

      > .inner {
        grid-template-columns: repeat(3, auto);
        justify-content: start;
        padding-left: calc(clamp(20px, 5vw, 50px) + 15vw);

        .usp {
          opacity: 0.6;
          width: 60vw;
          transition: opacity 1s ease-in-out;

          &.active {
            opacity: 1;
          }
        }
      }
    }

    .navigation {
      display: block;
    }
  }
}
